<template>
  <div style="background: #f3f3f3"
       class="position-fixed t-0 b-0 l-0 r-0  z-index-999 justify-content-center pd-t-15 pd-b-20"
       :class="skinClass">
    <div class="w-fill h-fill bg-f bdr-15 position-relative column" style="max-width: 700px;">
      <div class="h-fill " style="overflow-y: hidden;border-top-left-radius:15px;border-top-right-radius: 15px ">
        <div class="text-align-center pd-10">{{ card.deckName }}</div>
        <iframe class="hide-scrollbar" :srcdoc="srcdoc" frameborder="0"
                style="width: 100%;border: none;height: 100%">
        </iframe>
      </div>
      <div v-if="cardStatus === 'qfmt'"
           class="h-60 justify-content-center align-items-center pd-t-8 pd-b-8 cursor-pointer position-relative"
           style="border-top: 1px #f3f3f3 solid" @click="onShowAnswerClick">
        <span>显示答案</span>
      </div>
      <div v-else class=" justify-content-center pd-t-8 pd-b-8" style="border-top: 1px #f3f3f3 solid;height: 80px">
        <div v-if="forgetBtn.show" @click="onBtnClick(forgetBtn)" id="forget-button"
             :style="answering?'opacity: .5':''"
             class="button justify-content-center align-items-center">
          <div class="text-align-center">
            <div class="align-items-center fs-14 text-strong">
              <img src="/static/image/edit_btn_forget_normal.png" class="w-14 h-14 mg-r-5">忘记
            </div>
            <div class="fs-10 time">{{ getIvlStr(forgetBtn.ivl) }}</div>
          </div>
        </div>
        <div v-if="hardBtn.show" @click="onBtnClick(hardBtn)" id="hard-button" :style="answering?'opacity: .5':''"
             class="button justify-content-center align-items-center">
          <div class="text-align-center">
            <div class="align-items-center fs-14 text-strong">
              <img src="/static/image/edit_btn_hard_normal.png" class="w-14 h-14 mg-r-5">困难
            </div>
            <div class="fs-10 time">{{ getIvlStr(hardBtn.ivl) }}</div>
          </div>
        </div>
        <div v-if="goodBtn.show" @click="onBtnClick(goodBtn)" id="good-button" :style="answering?'opacity: .5':''"
             class="button justify-content-center align-items-center">
          <div class="text-align-center">
            <div class="align-items-center fs-14 text-strong">
              <img src="/static/image/edit_btn_good_normal.png" class="w-14 h-14 mg-r-5">一般
            </div>
            <div class="fs-10 time">{{ getIvlStr(goodBtn.ivl) }}</div>
          </div>
        </div>
        <div v-if="easyBtn.show" @click="onBtnClick(easyBtn)" id="easy-button" :style="answering?'opacity: .5':''"
             class="button justify-content-center align-items-center">
          <div class="text-align-center">
            <div class="align-items-center fs-14 text-strong">
              <img src="/static/image/edit_btn_easy_normal.png" class="w-14 h-14 mg-r-5">简单
            </div>
            <div class="fs-10 time">{{ getIvlStr(easyBtn.ivl) }}</div>
          </div>
        </div>
      </div>
      <div class="position-absolute b-10 w-100" style="right: -100px;">
        <div class="position-relative mg-l-50">
          <div v-for="(option,key,index) in options" @click.stop="onCardOptionClick(option)"
               class="options-item position-absolute bg-f w-48 h-48 bdr-circular cursor-pointer z-index-1 align-items-center justify-content-center"
               :class="{'shadow-2':optionsSwitch}"
               :style="'bottom:'+((option.ord+1)*(optionsSwitch?70:0))+'px'"
               :key="index">
            <div v-if="option.id == 'flag'" :title="option.name">
              <el-popover placement="bottom" width="190" v-model="visibleFlags">
                <span slot="reference" class="pd-10">
                <i v-if="card.flags > 0" class="el-icon-s-flag fs-22 mg-l-4"
                   :style="'color:'+util.getFlagColor(card.flags)"></i>
                <img v-else slot="reference" :src="option.icon" width="26" height="26">
                </span>
                <div class="display-flex">
                  <div @click="onFlagClick(0)" class="pd-5">
                    <i slot="reference" class="el-icon-s-flag fs-16 mg-l-10 cursor-pointer c-9"></i>
                  </div>
                  <div @click="onFlagClick(1)" class="pd-5">
                    <i slot="reference" class="el-icon-s-flag fs-16 mg-l-10 cursor-pointer c-app-red "></i>
                  </div>
                  <div @click="onFlagClick(2)" class="pd-5">
                    <i slot="reference" class="el-icon-s-flag fs-16 mg-l-10 cursor-pointer c-app-yellow"></i>
                  </div>
                  <div @click="onFlagClick(3)" class="pd-5">
                    <i slot="reference" class="el-icon-s-flag fs-16 mg-l-10 cursor-pointer c-app-green"></i>
                  </div>
                  <div @click="onFlagClick(4)" class="pd-5">
                    <i slot="reference" class="el-icon-s-flag fs-16 mg-l-10 cursor-pointer c-app-blue"></i>
                  </div>
                </div>
              </el-popover>
            </div>
            <div v-else :title="option.name">
              <img v-if="option.icon" :src="option.icon" width="26" height="26">
              <el-popover v-if="option.label"
                          placement="right"
                          width="180"
                          trigger="hover"
              >
                <div class="mg-b-5">剩余卡片</div>
                <span class="c-c text-strong fs-16" slot="reference">{{ option.label }}</span>
              </el-popover>
              <el-popover v-if="option.id=='keys'"
                          placement="right"
                          width="180"
                          trigger="hover"
              >
                <div class="mg-b-5">按键空格键 表示'显示答案'</div>
                <div class="mg-b-5">按键1 表示'<span class="c-app-red">忘记</span>'</div>
                <div class="mg-b-5">按键2 表示'<span class="c-app-yellow">困难</span>'</div>
                <div class="mg-b-5">按键3 表示'<span class="c-app-blue">一般</span>'</div>
                <div class="mg-b-5">按键4 表示'<span class="c-app-green">简单</span>'</div>
                <div class="mg-b-5">长按超过1.5秒未释放，程序将放弃此操作</div>
                <span class="c-c text-strong fs-12" slot="reference">快捷键</span>
              </el-popover>
            </div>
          </div>
          <div :style="optionsSwitch?'':'transform: rotate(180deg)'"
               class="position-absolute b-0 w-48 h-48 cursor-pointer justify-content-center align-items-center  z-index-99"
               :class="{'shadow-2 bg-f bdr-circular ':!optionsSwitch}" @click="optionsSwitch = !optionsSwitch">
            <i class="el-icon-arrow-down fs-20 c-c text-strong"></i>
          </div>
        </div>
      </div>
    </div>
    <remark-dialog ref="remarkDialog" :card-id="card.id"></remark-dialog>
  </div>
</template>

<script>
import request from '../libs/api.request'
import htmlTpl from '../libs/HtmlTpl'
import util from '../libs/util'
import RemarkDialog from "@/components/RemarkDialog";
import Da from "element-ui/src/locale/lang/da";

export default {
  name: "studyViewer",
  components: {RemarkDialog},
  props: {
    did: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      srcdoc: '',
      currentSkin: 'sun',
      util: util,
      options: {
        close: {ord: 0, id: 'close', name: '退出学习', icon: '/static/image/side_toolbar_closed_normal.png'},
        count: {ord: 1, id: 'count', name: '剩余卡片', icon: '', label: '1'},
        remark: {ord: 2, id: 'remark', name: '助记', icon: '/static/image/icon_note_zhuji@2x.png'},
        flag: {ord: 3, id: 'flag', name: '标记', icon: '/static/image/side_toolbar_flag_normal.png'},
        edit: {ord: 4, id: 'edit', name: '编辑', icon: '/static/image/side_toolbar_edit_normal.png'},
        destroy: {ord: 5, id: 'destroy', name: '删除', icon: '/static/image/side_toolbar_delete_normal.png'},
        skin: {ord: 6, id: 'skin', name: '模式', icon: '/static/image/side_toolbar_skin_light_normal.png'},
        keys: {ord: 7, id: 'keys', name: '快捷键', icon: ''},
        // switch: {ord: 5, id: 'switch', name: '切换记忆库', icon: '/static/image/side_toolbar_menu_normal.png'},
      },
      optionsSwitch: true,
      note: {},
      models: {},
      colConf: {},
      colUsn: 0,
      dconfs: {},
      decks: {},
      crtDue: 0,
      cardStatus: 'qfmt',//qfmt 正面  afmt 背面
      card: {},
      currentModel: {},
      hardBtn: {},
      goodBtn: {},
      forgetBtn: {},
      easyBtn: {},
      skinClass: '',
      answering: false,//答案提交中
      useTime: 0,
      internal: null,
      keyInterval: null,
      visibleFlags: false,
    }
  },
  methods: {
    onFlagClick(flags) {
      this.visibleFlags = false;
      let oldFlags = this.card.flags;
      this.card.flags = flags;
      request.request({
        url: '/space.card/setFlags',
        method: 'post',
        data: {cardId: this.card.id, flags: flags}
      }).then((data) => {

      }).catch((e) => {
        this.$message.error(e);
        this.card.flags = oldFlags;
      })
    },
    getDConf(card) {
      // let did = card.odid > 0 ? card.odid : card.did;
      // return this.dconfs[this.decks[did].conf];
      console.log(">>>");
      console.log(this.decks);
      console.log(card.did);
      console.log(this.dconfs[this.decks[card.did]]);
      return this.dconfs[this.decks[card.did].conf];
    },
    onBtnClick(btn) {
      if (this.answering)
        return;
      this.getStudyCard(btn);
    },
    onCardOptionClick(option) {
      switch (option.id) {
        case 'close':
          window.removeEventListener("keydown", this.handleKeyDown, true);
          window.removeEventListener("keyup", this.handleKeyUp, true);
          this.$emit('close-click');
          break;
        case 'skin':
          if (this.currentSkin === 'sun') {
            this.options.skin.icon = '/static/image/side_toolbar_skin_dark_normal.png';
            this.currentSkin = 'moon';
            this.skinClass = 'filter-invert skin-moon';
          } else {
            this.options.skin.icon = '/static/image/side_toolbar_skin_light_normal.png';
            this.currentSkin = 'sun';
            this.skinClass = 'skin-sun';
          }
          break;
        case 'flag':
          this.visibleFlags = true;
          break;
        case 'edit':
          window.removeEventListener("keydown", this.handleKeyDown, true);
          window.removeEventListener("keyup", this.handleKeyUp, true);
          this.$emit('edit-click', this.card);
          break;
        case 'remark':
          window.removeEventListener("keydown", this.handleKeyDown, true);
          window.removeEventListener("keyup", this.handleKeyUp, true);
          this.$refs.remarkDialog.open();
          break;
        case 'destroy':
          this.$confirm('是否删除该卡牌?', '提示', {
            confirmButtonText: '确认删除',
            cancelButtonText: '取消',
            type: 'warning',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                let loading = this.$loading();
                request.request({
                  url: '/space.card/deleteCard',
                  method: 'post',
                  data: {id: this.card.id,}
                }).then((data) => {
                  done();
                  loading.close();
                  this.$message.success('已删除');
                  this.getStudyCard(null);
                }).catch((e) => {
                  done()
                  loading.close();
                  this.$message.error(e);
                });
              } else {
                done();
              }
            }
          })
          break;
        case 'pause':
          this.$confirm('是否暂停该卡牌?', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                let loading = this.$loading();
                request.request({
                  url: '/space.card/pauseStart',
                  method: 'post',
                  data: {id: this.card.id, type: 'pause'}
                }).then((data) => {
                  done();
                  loading.close();
                  let start = {ord: 8, id: 'start', name: '恢复', icon: '/static/image/side_toolbar_start.png'};
                  delete this.options.pause;
                  this.$set(this.options, 'start', start);
                  this.$parent.getCardList();
                  this.$message.success('已暂停');
                }).catch((e) => {
                  done()
                  loading.close();
                  this.$message.error(e);
                });
              } else {
                done();
              }
            }
          })
          break;
        case 'start':
          this.$confirm('该卡牌已暂停，是否恢复该卡牌?', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                let loading = this.$loading();
                request.request({
                  url: '/space.card/pauseStart',
                  method: 'post',
                  data: {id: this.card.id, type: 'start'}
                }).then((data) => {
                  done();
                  loading.close();
                  let pause = {ord: 8, id: 'pause', name: '暂停', icon: '/static/image/side_toolbar_pause.png'};
                  delete this.options.start;
                  this.$set(this.options, 'pause', pause)
                  this.$parent.getCardList();
                  this.$message.success('已恢复');
                }).catch((e) => {
                  done()
                  loading.close();
                  this.$message.error(e);
                });
              } else {
                done();
              }
            }
          })
          break;
      }
    },
    onShowAnswerClick() {
      this.cardStatus = 'afmt';
      this.rendHtml();
    },
    getStudyData() {
      let loading = this.$loading();
      request.request({
        url: '/space.deck/getStudyData',
        params: {did: this.did},
      }).then((data) => {
        console.log(data);
        this.models = data.models;
        this.dconfs = data.dconfs;
        this.decks = data.decks;
        this.colConf = data.colConf;
        this.crtDue = data.crtDue;
        this.colUsn = data.colUsn;
        this.getStudyCard(null);
      }).catch((e) => {
        loading.close();
        console.log('错误');
        this.$alert(e.message, {callback: () => location.reload()})
      })
    },
    setCardFlds(flds) {
      this.card.flds = flds;
      this.rendHtml();
    },
    getStudyCard(answerBtn) {
      this.answering = true;
      request.request({
        url: '/space.card/getNextStudyCard',
        method: 'post',
        data: Object.assign(
          {ownerDid: this.did},
          answerBtn ? this.card : {},
          answerBtn ? {
            btnType: answerBtn.type,
            nextIvl: answerBtn.ivl,
            ease: answerBtn.ease,
            useTime: this.useTime,
            colUsn: this.colUsn,
          } : {}),
      }).then((data) => {
        this.$refs.remarkDialog.close();
        this.answering = false;
        if (!answerBtn) this.$loading().close();
        if (data.completed) {
          this.$alert('恭喜！你已经完成了目前的学习任务。', {
            callback: () => {
              this.$emit('completed');
            }
          });
        } else {
          this.options.count.label = data.cardCount;
          this.card = data.card;
          if (this.card) {
            this.card.flds = this.card.flds.split("\x1f");
          }
          let pause = {ord: 8, id: 'pause', name: '暂停', icon: '/static/image/side_toolbar_pause.png'};
          let start = {ord: 8, id: 'start', name: '恢复', icon: '/static/image/side_toolbar_start.png'};
          if (this.card.queue == -1) {//已经暂停了
            delete this.options.pause;
            this.options.start = start;
          } else {
            delete this.options.start;
            this.options.pause = pause;
          }
          this.showCard();
        }
      }).catch((e) => {
        this.answering = false;
        if (!answerBtn) {
          this.$loading().close();
          this.$alert(e.message, {callback: () => location.reload()})
        } else {
          this.$message.error(e);
        }
      })
    },
    findHideIfHas(content, name, value, start) {
      if (start === undefined) {
        start = -1;
      }

      let startPos = content.indexOf('{{^' + name + '}}', start);
      if (startPos > -1) {
        if (value === '') {
          content = content.replace('{{^' + name + '}}', '');
          content = content.replace('{{/' + name + '}}', '');
          if (content.indexOf('{{^' + name + '}}') > -1) {
            content = this.findHideIfHas(content, name, value)
          }
        } else {
          let endPos = content.indexOf('{{/' + name + '}}', startPos);
          if (endPos > 0) {
            content = content.substr(0, startPos) + content.substr(endPos + ('{{/' + name + '}}').length);
            if (content.indexOf('{{^' + name + '}}', endPos)) {
              content = this.findHideIfHas(content, name, value, endPos)
            }
          }
        }
      }

      return content;
    },
    findHideIfEmpty(content, name, value, start) {
      if (start === undefined) {
        start = -1;
      }

      let startPos = content.indexOf('{{#' + name + '}}', start);
      if (startPos > -1) {
        if (value !== '') {
          content = content.replace('{{#' + name + '}}', '');
          content = content.replace('{{/' + name + '}}', '');
        } else {
          let endPos = content.indexOf('{{/' + name + '}}', startPos);
          if (endPos > 0) {
            content = content.substr(0, startPos) + content.substr(endPos + ('{{/' + name + '}}').length);
            if (content.indexOf('{{#' + name + '}}') > -1) {
              content = this.findHideIfEmpty(content, name, value)
            }
          }
        }
      }

      return content;
    },
    findHint(content, name, value) {
      let id = Math.round(Math.random() * 10000000);
      let hint = `<a class=hint href= "#"
          onclick="this.style.display='none';document.getElementById('hint${id}').style.display='block';return false;">
          显示${name}
          </a >
          <div id="hint${id}" class=hint style="display: none">${value}</div>`;
      content = content.replace("{{hint:" + name + "}}", hint);
      if (content.indexOf("{{hint:" + name + "}}") > -1) {
        content = this.findHint(content, name, value);
      }
      return content;
    },
    findType(content, name, value) {
      if (this.cardStatus === 'qfmt') {
        sessionStorage.setItem("type_input_value", "");
        let inputName = 'input_' + new Date().getTime();
        let replace = `<div style="text-align: center;margin-top: 20px">
<input id="${inputName}_answer" name="answer" style="width: 90%;padding: 10px" placeholder="请输入答案" onkeydown="mOnKeyDown()"/>
<script>
    let ${inputName}_answer = document.getElementById('${inputName}_answer');
    ${inputName}_answer.oninput = function(){
      sessionStorage.setItem("type_input_value",${inputName}_answer.value);
    }
<\/script>
`;
        content = content.replace("{{type:" + name + "}}", replace);
      } else {
        // <div id="xxx"></div>
        //     <script>
        //     let inputValue = sessionStorage.getItem("type_input_value");
        //   if (inputValue === undefined || inputValue === null || inputValue === "") {
        //     inputValue = "&nbsp;&nbsp;&nbsp;";
        //   }
        //   let right = inputValue === \`${value}\`;
        //     let replace = "<div style='margin-top: 20px'><span style='padding: 5px;background-color: " + (right ? '#28BEA0' : '#FF0000') + "'>" + inputValue + "</span></div>";
        //     replace += "<div style='margin: 10px'>↓</div>";
        //     replace += "<div><span style='padding: 5px;background-color: #f3f3f3'> ${value} </span></div>";
        //
        //     document.getElementById('xxx').innerHTML = replace;
        //   <\/script>

        let inputValue = sessionStorage.getItem("type_input_value");
        if (inputValue === undefined || inputValue === null || inputValue === "") {
          inputValue = "&nbsp;&nbsp;&nbsp;";
        }

        let replace = "<div style='margin-top: 20px'><span style='padding: 5px;background-color: " + (inputValue === value ? '#28BEA0' : '#FF0000') + "'>" + inputValue + "</span></div>";
        replace += "<div style='margin: 10px'>↓</div>";
        replace += "<div><span style='padding: 5px;background-color: #f3f3f3'> " + value + "</span></div>";
        content = content.replace("{{type:" + name + "}}", replace);
      }
      return content;
    },
    showCard() {
      this.hardBtn = {ivl: 0, type: 'hard', show: false, ease: 0};
      this.goodBtn = {ivl: 0, type: 'good', show: false, ease: 0};
      this.forgetBtn = {ivl: 0, type: 'forget', show: false, ease: 0};
      this.easyBtn = {ivl: 0, type: 'easy', show: false, ease: 0};
      // 渲染一般，简单。。。四个按钮
      if (this.card) {
        let conf = this.getDConf(this.card);
        let lrnConf = this.card.type == '2' || this.card.type == '3' ? conf.lapse : conf.new;
        console.log(">>>>>>>>>>>>>>>>>>>>>>>>>");
        console.log('card', this.card);
        console.log('dconf', conf);
        console.log('model', this.models[this.card.mid]);
        console.log('deck', this.decks[this.card.did]);
        let btnCount = 0;
        if (this.card.odue != '0') {
          if (this.card.type == '0' || this.card.type == '1' || lrnConf.delays.length > 1) {
            btnCount = 3;
          } else {
            btnCount = 2;
          }
        } else if (this.card.queue == '2') {
          btnCount = 4;
        } else {
          btnCount = 3;
        }

        this.card.ivl = parseInt(this.card.ivl);
        this.card.left = parseInt(this.card.left);
        this.card.due = parseInt(this.card.due);
        this.card.factor = parseFloat(this.card.factor);
        if (this.card.queue == '0' || this.card.queue == '1' || this.card.queue == '3') {
          this.forgetBtn = Object.assign(this.forgetBtn, {ivl: lrnConf.delays[0] * 60, ease: 1, show: true});
          if (this.card.queue == '0') {
            this.card.left = lrnConf.delays.length * 1001;
          }

          console.log("this.card.left = " + this.card.left);
          if (btnCount == 2) {
            this.goodBtn = Object.assign(this.goodBtn, {ivl: this.card.ivl * 86400, ease: 2, show: true});
          } else {
            let left = this.card.left % 1000 - 1;
            if (left <= 0) {
              if (this.card.type == '2') {
                this.goodBtn = Object.assign(this.goodBtn, {ivl: this.card.ivl * 86400, ease: 2, show: true});
              } else {
                this.goodBtn = Object.assign(this.goodBtn, {ivl: lrnConf.ints[0] * 86400, ease: 2, show: true});
              }
            } else {
              let index = lrnConf.delays.length - left;
              index = Math.min(lrnConf.delays.length - 1, index);
              index = Math.max(0, index);
              this.goodBtn = Object.assign(this.goodBtn, {ivl: lrnConf.delays[index] * 60, ease: 2, show: true});
            }
            if (this.card.type == '2') {
              this.easyBtn = Object.assign(this.easyBtn, {ivl: this.card.ivl * 86400, ease: 3, show: true});
            } else {
              this.easyBtn = Object.assign(this.easyBtn, {ivl: lrnConf.ints[1] * 86400, ease: 3, show: true});
            }
          }
        } else {
          this.forgetBtn = Object.assign(this.forgetBtn, {ivl: lrnConf.delays[0] * 60, ease: 1, show: true});
          let delay = parseInt(Math.max(0, this.crtDue - this.card.due));
          console.log("delay = " + delay);
          let revConf = conf.rev;
          let fct = this.card.factor / 1000.0;
          console.log("fct = " + fct);

          let ivlFct = revConf.ivlFct === undefined ? 1 : parseFloat(revConf.ivlFct);
          let ivl2 = this.constrainedIvl((this.card.ivl + parseInt(delay / 4)) * 1.2, ivlFct, revConf.maxIvl, this.card.ivl);
          let ivl3 = this.constrainedIvl((this.card.ivl + parseInt(delay / 2)) * fct, ivlFct, revConf.maxIvl, ivl2);
          let ivl4 = this.constrainedIvl((this.card.ivl + delay) * fct * revConf.ease4, ivlFct, revConf.maxIvl, ivl3);

          console.log("ivl2 = " + ivl2);
          console.log("ivl3 = " + ivl3);
          console.log("ivl4 = " + ivl4);
          this.hardBtn = Object.assign(this.hardBtn, {ivl: ivl2 * 86400, ease: 2, show: true});
          this.goodBtn = Object.assign(this.goodBtn, {ivl: ivl3 * 86400, ease: 3, show: true});
          this.easyBtn = Object.assign(this.easyBtn, {ivl: ivl4 * 86400, ease: 4, show: true});

        }
      }

      this.useTime = 0;
      if (this.internal == null) {
        this.internal = setInterval(() => {
          this.useTime = this.useTime + 1;
        }, 1000);
      }
      this.currentModel = this.models[this.card.mid];
      this.cardStatus = 'qfmt';
      this.rendHtml();
    },
    findMedia(content) {
      let arr = content.match(/\[sound:([\w\S\s]+?)\]/gi);
      if (arr && arr.length > 0) {
        arr.forEach((v, k) => {
          let src = v.substring(7, v.length - 1);
          let id = Math.round(Math.random() * 10000000);
          let replace;
          if (k === 0) {
            replace = `<i class='iconfont icon-play'  onclick='mPlayAudio("${id}")' style='padding: 5px;font-size: 20px;cursor: pointer'><audio autoplay id='${id}' class='ak-play-audio'><source src="/${src}"></audio></i>`;
          } else {
            replace = `<i class='iconfont icon-play'  onclick='mPlayAudio("${id}")' style='padding: 5px;font-size: 20px;cursor: pointer'><audio class='ak-play-audio' id='${id}'><source src="/${src}"></audio></i>`;
          }
          content = content.replace(v, replace);
        });
      }
      return content;
    },
    rendHtml() {
      let html = htmlTpl;
      let tmplsIndex = Math.min(this.card.ord ? parseInt(this.card.ord) : 0, this.currentModel.tmpls.length - 1);
      let content = this.currentModel.tmpls[tmplsIndex][this.cardStatus];
      if (this.cardStatus === 'afmt') {
        content = content.split("{{FrontSide}}").join(this.currentModel.tmpls[tmplsIndex]['qfmt']);
        html = html.replace("::class::", 'ck-back');
      } else {
        html = html.replace("::class::", 'ck-front');
      }
      content = content.replace(new RegExp("{{=<% %>=}}", "gm"), "");
      content = content.replace(new RegExp("<%", "gm"), "{{");
      content = content.replace(new RegExp("%>", "gm"), "}}");
      this.currentModel.flds.forEach((v, k) => {
        let name = v.name.replace('[', '\\[').replace(']', '\\]');
        let hasCloze = content.indexOf('{{cloze:' + v.name + '}}') >= 0;
        let hasFiled = content.indexOf('{{' + v.name + '}}') >= 0;
        let flds = this.card.flds[k];
        let clozeArr = [];
        let mathBlockArr = flds.match(/\\\((.+?)\\\)|\\\[(.+?)\\\]/g);
        if (mathBlockArr) {
          mathBlockArr.forEach((v, k) => {
            clozeArr = v.match(/\{\{c([\w\W]*?)\}\}/gi);
            if (clozeArr) {
              console.log(v);
              let origin = v;
              console.log(clozeArr);
              clozeArr.forEach((v2, k2) => {
                let newArr = v2.substr(3, v2.length - 5).split('::');
                if (newArr.length >= 2) {
                  let hint = '...';
                  if (newArr.length === 3) {
                    hint = newArr[2];
                  }

                  if (this.cardStatus === 'afmt') {
                    v = v.replace(v2, newArr[1])
                  } else {
                    if (!hasCloze || parseInt(newArr[0]) === (parseInt(this.card.ord) + 1)) {
                      v = v.replace(v2, '[' + hint + ']')
                    } else {
                      v = v.replace(v2, newArr[1])
                    }
                  }
                }
              });
              flds = flds.replace(origin, v);
            }
          });
        }

        clozeArr = flds.match(/\{\{c([\w\W]*?)\}\}/gi);
        if (clozeArr) {
          clozeArr.forEach((v, k) => {
            let newArr = v.substr(3, v.length - 5).split('::');
            if (newArr.length >= 2) {
              let hint = '...';
              if (newArr.length === 3) {
                hint = newArr[2];
              }

              if (this.cardStatus === 'afmt') {
                if (!hasCloze || parseInt(newArr[0]) === (parseInt(this.card.ord) + 1)) {
                  flds = flds.replace(v, "<span class='cloze'>" + newArr[1] + "</span>")
                } else {
                  flds = flds.replace(v, newArr[1])
                }
              } else {
                if (!hasCloze || parseInt(newArr[0]) === (parseInt(this.card.ord) + 1)) {
                  flds = flds.replace(v, "<span class='cloze'>[" + hint + "]</span>")
                } else {
                  flds = flds.replace(v, newArr[1])
                }
              }
            }
          })
        }

        if (hasCloze) {
          if (v.name !== 'Text' && v.name.indexOf('Text') === 0) {
            let txtOrd = parseInt(v.name.replace('Text', ''));
            content = content.replace(new RegExp("{{cloze:" + this.escapeRegExp(name) + "}}", "gm"), txtOrd === parseInt(this.card.ord) + 1 ? this.card.flds[k] : '');
          } else {
            content = content.replace(new RegExp("{{cloze:" + this.escapeRegExp(name) + "}}", "gm"), flds);
          }

          if (hasFiled) {
            content = content.replace(new RegExp("{{" + this.escapeRegExp(name) + "}}", "gm"), this.card.flds[k]);
          }
        } else if (hasFiled) {
          content = content.replace(new RegExp("{{" + this.escapeRegExp(name) + "}}", "gm"), flds);
        }

        if (content.indexOf("{{kana:" + name + "}}") > 0) {
          content = content.replace(new RegExp("{{kana:" + this.escapeRegExp(name) + "}}", "gm"), this.kanaString(flds));
        }
        content = content.replace(new RegExp("{{furigana:" + this.escapeRegExp(name) + "}}", "gm"), flds);
        content = content.replace(new RegExp("{{ " + this.escapeRegExp(name) + " }}", "gm"), flds);
        content = content.replace(new RegExp("{{edit:" + this.escapeRegExp(name) + "}}", "gm"), flds);
        content = this.findHideIfEmpty(content, v.name, flds);
        content = this.findHideIfHas(content, v.name, flds);
        if (content.indexOf('{{hint:' + v.name) > -1) {
          content = this.findHint(content, v.name, flds);
        }
        if (content.indexOf('{{type:' + v.name) > -1) {
          content = this.findType(content, v.name, flds);
        }
      });
      content = content.replace(new RegExp("{{Tags}}", "gm"), this.card.tags);
      content = content.replace(new RegExp("{{Deck}}", "gm"), this.decks[this.card.did].name);
      content = content.replace(new RegExp("{{Subdeck}}", "gm"), this.decks[this.card.did].name);
      content = this.findHideIfEmpty(content, 'Tags', this.card.tags);
      content = this.findHideIfHas(content, 'Tags', this.card.tags);
      content = this.findMedia(content);
      let splitContent = html.split("{{content}}");
      html = splitContent[0] + content + splitContent[1];


      let style = this.currentModel['css'];
      let splitStyle = html.split("{{style}}");
      html = splitStyle[0] + style + splitStyle[1];
      html = html.replace("{{script}}", "");
      html = html.replace(new RegExp("&nbsp;", "gm"), " ");
      this.srcdoc = html;
    },
    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& 表示匹配的子字符串
    },
    getIvlStr(ivl) {
      let ivlStr = '';
      if (Math.abs(ivl) < 60) {
        ivlStr = ivl + '秒';
      } else if (Math.abs(ivl) < 3600) {
        ivlStr = Math.round(ivl / 60) + '分钟';
      } else if (Math.abs(ivl) < 86400) {
        ivlStr = Math.round(ivl / 3600) + '小时';
      } else if (Math.abs(ivl) < 86400 * 30) {
        ivlStr = Math.round(ivl / 86400) + '天';
      } else if (Math.abs(ivl) < 86400 * 30 * 12) {
        ivlStr = (ivl / (86400 * 30)).toFixed(1) + '月';
      } else {
        ivlStr = (ivl / (86400 * 30 * 12)).toFixed(1) + '年';
      }
      if (ivl === 0) {
        return '结束';
      } else if (ivl < this.colConf.collapseTime) {
        return "<" + ivlStr;
      } else {
        return ivlStr + "后";
      }
    },
    constrainedIvl(ivl, ivlFct, maxIvl, prev) {
      ivl = Math.max(parseInt(ivl * ivlFct), parseFloat(prev) + 1);
      ivl = Math.max(parseInt(ivl), 1);
      return Math.min(ivl, maxIvl);
    },
    //键盘按下
    handleKeyDown(e) {
      let that = this;
      let key = e.key;
      console.log(key);
      if (key == ' ' && that.cardStatus == 'qfmt') {
        that.onShowAnswerClick();
      }
      if (that.cardStatus == 'afmt') {//qfmt 正面  afmt 背面
        let downTime = localStorage.getItem('keyDown');
        if (downTime) {
          let upTime = new Date().getTime();
          if (upTime - downTime > 1500) {//按键超过1.5秒未释放不做操作
            $("#forget-button").css({
              "border": "2px solid #F7F7F7",
            });
            $("#good-button").css({
              "border": "2px solid #F7F7F7",
            });
            $("#hard-button").css({
              "border": "2px solid #F7F7F7",
            });
            $("#easy-button").css({
              "border": "2px solid #F7F7F7",
            });
          }
          return;
        }
        let time = new Date().getTime();
        localStorage.setItem('keyDown', time);
        if (key == '1') {//忘记
          if (that.forgetBtn.show) {
            e.preventDefault();
            $("#forget-button").css({
              "border": "2px solid #FF5507",
            });
          } else {
            that.$message.warning('没有该操作项哦');
          }

        }
        if (key == '2') {//困难
          if (that.hardBtn.show) {
            e.preventDefault()
            $("#hard-button").css({
              "border": "2px solid #FFB200",
            });
          } else {
            that.$message.warning('没有该操作项哦');
          }
        }
        if (key == '3') {//一般
          if (that.goodBtn.show) {
            e.preventDefault()
            $("#good-button").css({
              "border": "2px solid #018DFF",
            });
          } else {
            that.$message.warning('没有该操作项哦');
          }
        }
        if (key == '4') {//简单
          if (that.easyBtn.show) {
            e.preventDefault()
            $("#easy-button").css({
              "border": "2px solid #28BEA0",
            });
          } else {
            that.$message.warning('没有该操作项哦');
          }
        }
      }
    },
    //键盘松开
    handleKeyUp(e) {
      let that = this;
      let key = e.key;
      console.log(key);
      if (that.cardStatus == 'afmt') {//qfmt 正面  afmt 背面
        $("#forget-button").css({
          "border": "2px solid #F7F7F7",
        });
        $("#good-button").css({
          "border": "2px solid #F7F7F7",
        });
        $("#hard-button").css({
          "border": "2px solid #F7F7F7",
        });
        $("#easy-button").css({
          "border": "2px solid #F7F7F7",
        });
        let upTime = new Date().getTime();
        let downTime = localStorage.getItem('keyDown');
        if (upTime - downTime > 1500) {//按键超过1.5秒未释放不做操作
          localStorage.removeItem('keyDown');
          return;
        }
        localStorage.removeItem('keyDown');
        if (key == '1') {//忘记
          if (that.forgetBtn.show) {
            e.preventDefault();
            that.onBtnClick(that.forgetBtn);
          } else {
            that.$message.warning('没有该操作项哦');
          }
        }
        if (key == '2') {//困难
          if (that.hardBtn.show) {
            e.preventDefault()
            that.onBtnClick(that.hardBtn);
          } else {
            that.$message.warning('没有该操作项哦');
          }
        }
        if (key == '3') {//一般
          if (that.goodBtn.show) {
            e.preventDefault()
            that.onBtnClick(that.goodBtn);
          } else {
            that.$message.warning('没有该操作项哦');
          }
        }
        if (key == '4') {//简单
          if (that.easyBtn.show) {
            e.preventDefault()
            that.onBtnClick(that.easyBtn);
          } else {
            that.$message.warning('没有该操作项哦');
          }
        }
      }
    },
  },
  mounted() {
    this.getStudyData();
    this.keyInterval = setInterval(() => {
      console.log('定时器：')
      window.addEventListener("keydown", this.handleKeyDown, true);
      window.addEventListener("keyup", this.handleKeyUp, true);
    }, 1000);
  },
  created() {
    console.log('card viewer created>>>>>>>>');
    /** 阻止页面滚动 */
    document.body.style.cssText = 'overflow-y: hidden; height: 100%;';
    window.onEnterDown = () => {
      let inputValue = sessionStorage.getItem("type_input_value");
      if (inputValue !== undefined && inputValue !== "") {
        this.onShowAnswerClick();
      }
    }

    window.addEventListener("keydown", this.handleKeyDown, true);
    window.addEventListener("keyup", this.handleKeyUp, true);
  },
  destroyed() {
    if (this.internal) {
      clearInterval(this.internal);
      this.internal = null;
    }
    if (this.keyInterval) {
      clearInterval(this.keyInterval);
      this.keyInterval = null;
    }
    /** 取消阻止页面滚动 */
    document.body.style.cssText = 'overflow-y: auto; height: 100%;'
    window.removeEventListener("keydown", this.handleKeyDown, true);
    window.removeEventListener("keyup", this.handleKeyUp, true);
  }
}
</script>

<style scoped>
.button {
  cursor: pointer;
  flex: 1;
  background-color: #F7F7F7;
  border: 2px solid #F7F7F7;
  margin: 8px;
  border-radius: 10px;
}

.button .time {
  color: #999999;
}

/* .button:hover, .button:hover .time {
  color: #ffffff;
} */

/* .button:hover img {
  -webkit-filter: brightness(0) invert(1);*/
/*filter: brightness(0) invert(1);
} */

#easy-button:hover {
  border: 2px solid #28BEA0;
}

#good-button:hover {
  border: 2px solid #018DFF;
}

#forget-button:hover {
  border: 2px solid #FF5507;
}

#hard-button:hover {
  border: 2px solid #FFB200;
}

.options-item {
  transition: bottom 0.5s;
}

.iconfont-l {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  padding: 5px 30px;
  color: #ccc;
}

.iconfont-r {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  padding: 5px 30px;
  color: #ccc;
}
</style>
